import './App.css';

function App() {
  return (
    <h1>
      Admin
    </h1>
  );
}

export default App;
